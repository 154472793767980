@tailwind base;
@tailwind components;
@tailwind utilities;
@mixin under($breakpoint) {
    @media (max-width: calc($breakpoint - 1px)) {
        @content;
    }
}

body:has(.under-constructor) {
    background-color: #1e1e1e;
}

* {
    box-sizing: border-box;
    font-family: "Rubik", sans-serif;
    font-variant-ligatures: none;
}

.loginBackground {
    background-color: #131318;
    opacity: 1;
    background-image: repeating-radial-gradient(
            circle at 0 0,
            transparent 0,
            #09090b 10px
    ),
    repeating-linear-gradient(#13131555, #000);
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: #282828;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(57, 57, 57, 0.45);
    border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    @apply bg-zinc-950;
    border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-yellow-500;
}

.tradingview-widget-container {
    width: 100% !important;
    margin-bottom: 40px;
}

iframe {
    width: 100% !important;
}

iframe #mediumwidgetembed {
    width: 100% !important;
}

.tv-widget-chart__copyrightingContainer {
    display: none !important;
    color: red;
}

.tradingview-widget-container iframe:last-child {
    display: none !important;
}


.html body, html.theme-dark body {
    background-color: #09090b;
}

html.theme-dark .tv-widget-chart--with-border {
    border: none !important;
}

.notify-user {
    background-color: #5dc23556;
    border-radius: 4px;
}
.notification-item {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(15px, -15px);
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    z-index: 2;
}
div[class*=menu][class*=css] {
    color: black;
}

header.px-6 {
    padding: 0 0 0 8px !important;
    @include under(1024px) {
        padding: 0 0 0 4px !important;
    }
}

input[disabled] {
    opacity: 0.3;
    pointer-events: none;
}

.prose :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    margin-top: unset !important;
}

del {
    color: rgba(red, 0.5);
    text-decoration: none;
    position: relative;
    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 2px solid rgba(red, 0.8);
      height: 18px;
      position: absolute;
      bottom: 0;
      left: 2px;
      transform: rotate(-16deg);
    }
}
ins {
    color: white;
    font-size: 32px;
    text-decoration: none;
    padding: 1em 1em 1em .5em;
}

.p-dropdown-menu > ul {
    gap: 8px !important;
}